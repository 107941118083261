import {
  StyleSheet,
  Dimensions,
  StatusBar,
} from 'react-native'
import Platform from '@/lib/Platform'
const statusBarHeight = StatusBar.currentHeight || 0

const wideThreshold = 1024

const isWeb = Platform.OS === 'web'

const windowWidth = Dimensions.get('window').width
const window = {
  width:  (isWeb && windowWidth > wideThreshold) ? wideThreshold : windowWidth,
  height: Dimensions.get('window').height - statusBarHeight,
}

const division = 1 / 12

export const width = {
  hairline: StyleSheet.hairlineWidth,
  1:  window.width * division * 1,
  2:  window.width * division * 2,
  3:  window.width * division * 3,
  4:  window.width * division * 4,
  5:  window.width * division * 5,
  6:  window.width * division * 6,
  7:  window.width * division * 7,
  8:  window.width * division * 8,
  9:  window.width * division * 9,
  10:  window.width * division * 10,
  11:  window.width * division * 11,
  12:  window.width * division * 12,
}

export const height = {
  1: window.height * division * 1,
  2: window.height * division * 2,
  3: window.height * division * 3,
  4: window.height * division * 4,
  5: window.height * division * 5,
  6: window.height * division * 6,
  7: window.height * division * 7,
  8: window.height * division * 8,
  9: window.height * division * 9,
  10: window.height * division * 10,
  11: window.height * division * 11,
  12: window.height * division * 12,
}

export const space = {
  '0.5': 4,
  1: 8,
  2: 16,
  3: 24,
  4: 32,
  5: 40,
  6: 48,
  7: 56,
  8: 64,
  9: 72,
  10: 80,
}

export const fontSize = {
  sm: 12,
  md: 16,
  lg: 24,
}

export const fontColor = {
  base: '#212121',
  body: '#425468',
  label: 'rgb(88,88,88)',
}

export const color = {
  brand: 'rgb(68, 97, 160)',
  brandLight: '#EAF3FF',

  // https://mui.com/material-ui/customization/color/#2014-material-design-color-palettes

  //indigo: {
  //  50: '#e8eaf6',
  //},

  lightBlue: {
    50: '#e1f5fe',
    100: '#b3e5fc',
    900: '#01579b',
  },

  red: {
    100: '#ffccbc',
    900: '#b71c1c',
  },

  amber: {
    100: '#ffecb3',
    900: '#ff6f00',
  },

  grey: {
    50: '#fafafa',
    400: '#bdbdbd'
  }

}
